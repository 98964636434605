import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';
import TextField from '../../shared/Inputs/TextField';
import FormField from '../../shared/Inputs/FormField';
import CardItem from '../../shared/CardItem';
import { Component } from '../../../generated/types';
import { formatComponentSourceReference } from '../../../utils/formatters/formatComponentSourceReference';
// types
import { InsightsFormValues } from './types';
import { GLOBAL_CONTENT_WIDTH } from '../../Layout/constants';
import { StackComponentType } from '../../../pages/create/stacks';

const SUB_LABEL_TEXT =
  'Brief the AI on the intended use case of this data to help it deliver meaningful insights to your users. Provide examples of outputs that match your desired outcomes, considering elements such as structure, formatting, brevity, and style.';

interface InsightsFormProps {
  stack?: StackComponentType;
  baseDataComponent?: Component;
}

const InsightsForm = ({ stack, baseDataComponent }: InsightsFormProps) => {
  const { setFieldValue, values, errors } =
    useFormikContext<InsightsFormValues>();

  const isCreating = !stack && !!baseDataComponent;

  return (
    <Stack
      id='insights-step'
      maxWidth={GLOBAL_CONTENT_WIDTH}
      height='100%'
      spacing={4}
    >
      <FormField label='Created from'>
        <CardItem
          item={{
            id: 'content',
            ...formatComponentSourceReference(
              isCreating,
              baseDataComponent,
              stack?.originSourceMetadata,
            ),
            iconName: 'table',
            hasIconBackground: true,
          }}
        />
      </FormField>
      <TextField
        label='Prompt'
        labelOverflow
        subLabel={SUB_LABEL_TEXT}
        errorMessage={errors.prompt}
        value={values.prompt}
        onChange={(e) => setFieldValue('prompt', e.target.value)}
        fullHeight
        inputProps={{
          'aria-label': 'ai prompt input',
        }}
      />
    </Stack>
  );
};

export default InsightsForm;
