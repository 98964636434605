import { useState, useCallback, useContext } from 'react';
import { Stack, Typography } from '@mui/material';

import { BaseRegisteredDialogComponentProps } from './types';
import TextField from '../shared/Inputs/TextField';
import FormActionFooter from '../shared/FormActionFooter';
import { EnvironmentContext } from '../EnvironmentProvider';
import { useEnvironmentComponents } from '../../hooks';
import {
  ComponentType,
  CreateDataComponentFromChatResponseInput,
} from '../../generated/types';
export interface SaveToCreateProps extends BaseRegisteredDialogComponentProps {
  chatResponseId: string;
}

const SaveToCreate = ({ chatResponseId, hideDialog }: SaveToCreateProps) => {
  const [title, setTitle] = useState('');

  const { environment } = useContext(EnvironmentContext);
  const environmentId = environment?.id;
  const environmentName = environment?.name;

  const { createComponentByType, createComponentLoading: createQueryLoading } =
    useEnvironmentComponents();

  const handleSubmit = useCallback(async () => {
    if (!environmentId) return;
    const input: CreateDataComponentFromChatResponseInput = {
      chatResponseId,
      name: title,
    };
    await createComponentByType(ComponentType.DATA, environmentId, input);
    hideDialog();
  }, [createComponentByType, environmentId, chatResponseId, title, hideDialog]);

  return (
    <Stack spacing={2}>
      <Typography color='text.secondary'>
        {`${environmentName} administrators can view and share saved items or use
        them to create topics and stacks.`}
      </Typography>
      <TextField
        label='Title'
        subLabel='Choose a clear, descriptive title that reflects the purpose of this content.'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <FormActionFooter
        noPadding
        cancelAction={{
          label: 'Cancel',
          action: hideDialog,
        }}
        submitAction={{
          label: 'Save to Create',
          action: handleSubmit,
          isLoading: createQueryLoading,
          disabled: !title.trim(),
        }}
      />
    </Stack>
  );
};

export default SaveToCreate;
