import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';

import { useSessionInfo } from '../hooks';
import { getPalette } from './palette';
import { shadows, customShadows } from './shadow';
import { typography } from './typography';
import { getComponents } from './components';
import { useColorMode } from '../contexts/ColorModeContext';

interface MuiThemeProviderProps {
  children: React.ReactNode;
}

const MuiThemeProvider = ({ children }: MuiThemeProviderProps) => {
  const { effectiveMode } = useColorMode();
  const { customBranding } = useSessionInfo();

  const currentTheme = useMemo(() => {
    const modifiedPalette = getPalette(
      effectiveMode,
      customBranding?.themeColor,
    );

    // Create a base theme first to pass to getComponents
    const baseTheme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 720, // this is the only departure from the default MUI value (which was 600)
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      palette: {
        ...modifiedPalette,
        mode: effectiveMode,
      },
      typography: typography,
      shadows: shadows,
      customShadows: customShadows,
    });

    // Now create the final theme with components
    const newTheme = createTheme({
      ...baseTheme,
      components: getComponents(baseTheme),
    });

    return newTheme;
  }, [effectiveMode, customBranding?.themeColor]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
};

export default MuiThemeProvider;
