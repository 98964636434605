import { OriginSourceMetadata } from '@madeinventive/core-types';
import { Component, ComponentSourceType } from '../../generated/types';
import { formatByline } from './formatByline';

interface ComponentSourceReference {
  title: string;
  subTitle: string;
}

// this function handles the complex logic of when we want to use data from the original
// source directly vs. the copied source metadata at the time the component was created/derived
// from the original source
export const formatComponentSourceReference = (
  isCreating: boolean,
  component?: Component,
  sourceMetadata?: OriginSourceMetadata,
): ComponentSourceReference => {
  const originalSource =
    component?.originSourceType === ComponentSourceType.DATA_COMPONENT
      ? (component.originSource as Component) // this is the case where we're editing a Topic
      : component; // this is currently a hack to handle the case where we are editing a stack; and we're actually passing in the original source data as the component

  return {
    title: isCreating
      ? component?.name ?? 'Content'
      : sourceMetadata?.name ?? 'Content',
    subTitle: formatByline({
      createdBy: isCreating ? component?.createdBy : originalSource?.createdBy,
      createdAt: isCreating ? component?.createdAt : originalSource?.createdAt,
      updatedAt: isCreating ? component?.updatedAt : sourceMetadata?.updatedAt,
    }),
  };
};
