import { v3 as uuidv3 } from 'uuid';

const NAMESPACE = '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d';

export const generateInsightId = (body: string) => {
  if (!body || body.trim().length === 0) {
    throw new Error('Body cannot be empty when generating insight ID');
  }
  return uuidv3(body, NAMESPACE);
};
