import React from 'react';
import { useTheme } from '@mui/material/styles';
import styles from './loadingDots.module.css';

const LoadingDot = () => {
  const theme = useTheme();

  return (
    <span className={styles.loadingDots}>
      <span
        className={`${styles.dot} ${styles.dot1}`}
        style={{ color: theme.palette.text.primary }}
      >
        .
      </span>
      <span
        className={`${styles.dot} ${styles.dot2}`}
        style={{ color: theme.palette.text.primary }}
      >
        .
      </span>
      <span
        className={`${styles.dot} ${styles.dot3}`}
        style={{ color: theme.palette.text.primary }}
      >
        .
      </span>
    </span>
  );
};

export default LoadingDot;
