import Chip from '../Chip';
import { Stack } from '@mui/material';
import { IconName } from '../Icon/types';

export type ChipOptionType = {
  label: string;
  value: string;
  iconName?: IconName;
};

interface ToggleChipsProps {
  size?: 'small' | 'large';
  options: ChipOptionType[];
  selectedValues: string[];
  handleItemClick: (value: string) => void;
  flexWrap?: boolean;
  hasError?: boolean;
}

const ToggleChips = ({
  size = 'large',
  options,
  selectedValues,
  handleItemClick,
  flexWrap,
  hasError,
}: ToggleChipsProps) => {
  return (
    <Stack
      direction='row'
      spacing={flexWrap ? 0 : 1}
      flexWrap={flexWrap ? 'wrap' : 'nowrap'}
    >
      {options.map((option) => {
        const selected = selectedValues.includes(option.value);
        return (
          <Chip
            size={size}
            label={option.label}
            iconName={option.iconName}
            // It is important to add `selected` to the key
            // because the key is used to determine if the component should be re-rendered
            key={`${option.label}${selected ? 'selected' : ''}`}
            onClick={() => handleItemClick(option.value)}
            selected={selected}
            sx={{
              minWidth: 'unset',
              marginRight: flexWrap ? 1 : 0,
              marginBottom: flexWrap ? 1 : 0,
            }}
            hasError={hasError}
          />
        );
      })}
    </Stack>
  );
};

export default ToggleChips;
