import * as yup from 'yup';

export type MultiStepFormValues = {
  insights: InsightsFormValues;
  actions: ActionsFormValues;
  summary: SummaryFormValues;
};

export interface InsightsFormValues {
  prompt: string;
}

export interface ActionsFormValues {
  optInNotifications: boolean;
  includeActionButton: boolean;
  actionButtonText?: string;
  actionButtonLink?: string;
}
export interface SummaryFormValues {
  stackTitle: string;
  stackButtonText: string;
}

export const insightsInitialValues: InsightsFormValues = {
  prompt: '',
};

export const actionsInitialValues: ActionsFormValues = {
  optInNotifications: true,
  includeActionButton: true,
  actionButtonText: undefined,
  actionButtonLink: undefined,
};

export const summaryInitialValues: SummaryFormValues = {
  stackTitle: '',
  stackButtonText: '',
};

export const insightsValidationSchema = yup.object().shape({
  prompt: yup.string().trim().required('Please fill in a prompt.'),
});

export const actionsValidationSchema = yup.object().shape({
  optInNotifications: yup.boolean(),
  includeActionButton: yup.boolean(),
  actionButtonText: yup.string().when('includeActionButton', {
    is: true,
    then: yup.string().required('Please fill in button text.'),
  }),
  actionButtonLink: yup.string().when('includeActionButton', {
    is: true,
    then: yup
      .string()
      .url('Please fill in a valid URL.')
      .required('Please fill in button link.'),
  }),
});

export const summaryValidationSchema = yup.object().shape({
  stackTitle: yup.string().trim().required('Please fill in title.'),
  stackButtonText: yup.string().trim().required('Please fill in button text.'),
});
