import { ReactNode, useContext } from 'react';
import { EnvironmentContext } from '../EnvironmentProvider';
import PageError from '../PageError';
import { useEnvironmentAgentProfile } from '../../hooks/useEnvironmentAgentProfile';
import { useSessionInfo } from '../../hooks';

interface EnvironmentPauseGuardProps {
  children: ReactNode;
}

function isEnvironmentPaused(environmentId?: string): boolean {
  if (!environmentId) {
    return false;
  }

  try {
    const pausedEnvironments =
      process.env.NEXT_PUBLIC_PAUSED_ENVIRONMENT_IDS?.split(',') || [];
    const validPausedEnvs = pausedEnvironments
      .map((id) => id.trim())
      .filter((id) => id.length > 0);

    return validPausedEnvs.includes(environmentId.trim());
  } catch (error) {
    console.error('Error checking environment pause status:', error);
    return false;
  }
}

export function EnvironmentPauseGuard({
  children,
}: EnvironmentPauseGuardProps) {
  const { environment } = useContext(EnvironmentContext);
  const { analystName, isLoading } = useEnvironmentAgentProfile();
  const { userType } = useSessionInfo();
  const isPaused = isEnvironmentPaused(environment?.id);

  if (userType === 'SystemUser') {
    return <>{children}</>;
  }

  if (isPaused && !isLoading) {
    return (
      <PageError
        title={`${analystName} access paused`}
        message={`Your access to ${analystName} features has been temporarily paused.`}
      />
    );
  }

  return <>{children}</>;
}

export default EnvironmentPauseGuard;
