import { Stack, Box } from '@mui/material';
import { styled } from '@mui/system';
import Icon from '../Icon';
import { BadgeProps } from '../Badge';
import { IconName } from '../Icon/types';

interface IconStackedProps {
  icons: {
    iconName: IconName;
    badge?: IconName;
    badgeColor?: BadgeProps['color'];
  }[];
}

const StyledIconBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-2px',
    left: '-2px',
    right: '-2px',
    bottom: '-2px',
    borderRadius: '9px', // slightly larger than 8px border radius of boxed icon.
    border: `2px solid ${theme.palette.background.paper}`,
    pointerEvents: 'none', // Ensures it doesn’t interfere with content clicks
  },
}));

const IconStacked = ({ icons }: IconStackedProps) => {
  return (
    <Stack direction='row' spacing={-1} role='group' aria-label='stacked icons'>
      {icons.map(({ iconName, badge, badgeColor }) => (
        <StyledIconBox key={iconName}>
          <Icon
            name={iconName}
            isBoxed
            badgeName={badge}
            badgeColor={badgeColor}
          />
        </StyledIconBox>
      ))}
    </Stack>
  );
};

export default IconStacked;
