import { Stack, Typography, Box } from '@mui/material';
import Button from '../shared/Button';
import { useRouter } from 'next/router';

interface PageErrorProps {
  title: string;
  message: string;
  buttonText?: string;
  buttonHref?: string;
}

const PageError = ({
  title,
  message,
  buttonText,
  buttonHref,
}: PageErrorProps) => {
  const router = useRouter();

  return (
    <Box height='100vh' sx={{ bgcolor: 'background.paper' }}>
      <Stack
        alignItems='center'
        justifyContent='center'
        maxWidth={560}
        sx={{ height: '100%', margin: 'auto' }}
      >
        <Typography
          variant='h1'
          color='text.primary'
          sx={{ mb: 1, textAlign: 'center' }}
        >
          {title}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{ mb: 3, textAlign: 'center' }}
        >
          {message}
        </Typography>
        {buttonText && buttonHref && (
          <Button variant='contained' onClick={() => router.push(buttonHref)}>
            {buttonText}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default PageError;
