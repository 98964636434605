import { useCallback, useState } from 'react';
import { FeedbackRating, RatingReasonsInput } from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';
import RatingReasons from '../RatingReasons';
import DialogActions from '../shared/Dialog/DialogActions';

import { UseWorkspaceComponentReturnType } from '../../hooks/useWorkspaceComponent';

export interface ComponentInsightRatingReasonsProps
  extends BaseRegisteredDialogComponentProps {
  componentId: string;
  workspaceId: string;
  insight: string;
  insightDate: Date;
  rating: FeedbackRating;
  rateInsight: UseWorkspaceComponentReturnType['rateInsight'];
}

const ComponentInsightRatingReasons = ({
  componentId,
  workspaceId,
  insight,
  insightDate,
  rating,
  rateInsight,
  hideDialog,
}: ComponentInsightRatingReasonsProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [summary, setSummary] = useState('');
  const [comment, setComment] = useState('');

  const handleSubmit = useCallback(() => {
    const reasons: RatingReasonsInput = {};
    if (selectedTags.length > 0) reasons.tags = selectedTags.join(',');
    if (summary) reasons.summary = summary;
    if (comment) reasons.comment = comment;
    rateInsight({
      componentId,
      workspaceId,
      insight,
      insightDate,
      rating,
      reasons,
    });
    hideDialog();
  }, [
    comment,
    componentId,
    hideDialog,
    insight,
    insightDate,
    rateInsight,
    rating,
    selectedTags,
    summary,
    workspaceId,
  ]);

  return (
    <>
      <RatingReasons
        rating={rating}
        selectedTags={selectedTags}
        summary={summary}
        comment={comment}
        onTagsChange={setSelectedTags}
        onSummaryChange={setSummary}
        onCommentChange={setComment}
      />
      <DialogActions
        closeDialog={hideDialog}
        secondaryAction={{
          text: 'Skip',
          disabled: false,
        }}
        primaryAction={{
          text: 'Submit',
          action: handleSubmit,
          disabled: false,
        }}
      />
    </>
  );
};

export default ComponentInsightRatingReasons;
