import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

// types
import {
  Component,
  ComponentType,
  CreateDataComponentFromChatResponseInput,
  CreateDataComponentFromChatResponseMutationResult,
  CreateStackFromDataComponentInput,
  CreateStackFromDataComponentMutationResult,
  CreateTopicFromDataComponentInput,
  EditComponentInput,
  useCreateDataComponentFromChatResponseMutation,
  useCreateStackFromDataComponentMutation,
  useCreateTopicFromDataComponentMutation,
  useDeleteComponentMutation,
  useEditComponentMutation,
  useEnvironmentComponentsLazyQuery,
  CreateTopicFromDataComponentMutationResult,
} from '../generated/types';

// actions
import {
  EnvComponentType,
  setEnvComponentsByType as setEnvComponentsAction,
  removeEnvComponentByType as removeComponentAction,
  updateEnvComponentByType as updateComponentAction,
  addEnvComponentByType as addEnvComponentAction,
} from '../store/slices/environmentComponents';

// hooks
import { useAppDispatch } from './store';
import { useToast } from './';

// names for components for toast messages
const componentNames = {
  [ComponentType.DATA]: 'Content',
  [ComponentType.STACK]: 'Stack',
  [ComponentType.TOPIC]: 'Topic',
};

export const useEnvironmentComponents = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { showSuccessToast, showErrorToast, showDefaultToast } = useToast();

  const [getEnvironmentComponents, { loading }] =
    useEnvironmentComponentsLazyQuery();

  const [editComponent, { loading: editComponentLoading }] =
    useEditComponentMutation();

  const [deleteComponent, { loading: deleteComponentLoading }] =
    useDeleteComponentMutation();

  const [createDataComponentFromChatResponse, { loading: createQueryLoading }] =
    useCreateDataComponentFromChatResponseMutation();

  const [createTopicFromChatResponse, { loading: createTopicLoading }] =
    useCreateTopicFromDataComponentMutation();

  const [createStackFromDataComponent, { loading: createStackLoading }] =
    useCreateStackFromDataComponentMutation();

  const loadEnvironmentComponentsByType = useCallback(
    async (environmentId: string, componentType: EnvComponentType) => {
      const { data } = await getEnvironmentComponents({
        variables: {
          environmentId,
          params: {
            type: componentType,
          },
        },
      });

      if (data?.node?.__typename === 'Environment' && environmentId) {
        const components = data.node.components.edges
          .map((edge) => edge.node as Component)
          .filter((component) => component.type === componentType);
        dispatch(
          setEnvComponentsAction({
            environmentId,
            componentType: componentType,
            components,
          }),
        );
      }
    },
    [getEnvironmentComponents, dispatch],
  );

  const editEnvironmentComponentByType = useCallback(
    async (
      environmentId: string,
      input: EditComponentInput,
      componentType: EnvComponentType,
    ) => {
      const result = await editComponent({
        variables: { input },
      });

      if (result.data?.editComponent) {
        showSuccessToast(`${componentNames[componentType]} updated.`);
        const component = result.data.editComponent.component as Component;
        if (component && environmentId) {
          dispatch(
            updateComponentAction({
              environmentId,
              componentType: componentType,
              component,
            }),
          );
        } else {
          showErrorToast(
            `${componentNames[componentType]} not updated. Please retry.`,
          );
        }
      } else {
        showErrorToast(
          `${componentNames[componentType]} not updated. Please retry.`,
        );
      }
    },
    [editComponent, showSuccessToast, dispatch, showErrorToast],
  );

  const deleteEnvironmentComponentByType = useCallback(
    async (
      environmentId: string,
      componentId: string,
      componentType: EnvComponentType,
    ) => {
      const result = await deleteComponent({
        variables: { input: { componentId } },
      });

      if (result.data?.deleteComponent.success) {
        dispatch(
          removeComponentAction({
            environmentId,
            componentId,
            componentType: componentType,
          }),
        );
        return true;
      } else {
        showErrorToast(
          `${componentNames[componentType]} not deleted. Please retry.`,
        );
        return false;
      }
    },
    [deleteComponent, dispatch, showErrorToast],
  );

  type MutationTypeMap = {
    [ComponentType.DATA]: {
      input: CreateDataComponentFromChatResponseInput;
      result: CreateDataComponentFromChatResponseMutationResult;
    };
    [ComponentType.STACK]: {
      input: CreateStackFromDataComponentInput;
      result: CreateStackFromDataComponentMutationResult;
    };
    [ComponentType.TOPIC]: {
      input: CreateTopicFromDataComponentInput;
      result: CreateTopicFromDataComponentMutationResult;
    };
  };

  const componentTypeConfig = useMemo(
    () =>
      ({
        [ComponentType.DATA]: {
          mutation: createDataComponentFromChatResponse,
          redirectPath: '/create/queries',
        },
        [ComponentType.STACK]: {
          mutation: createStackFromDataComponent,
          redirectPath: '/create/stacks',
        },
        [ComponentType.TOPIC]: {
          mutation: createTopicFromChatResponse,
          redirectPath: '/create/topics',
        },
      } as const),
    [
      createDataComponentFromChatResponse,
      createStackFromDataComponent,
      createTopicFromChatResponse,
    ],
  );

  const handleResultComponent = useCallback(
    (
      component: Component,
      environmentId: string,
      componentType: EnvComponentType,
    ) => {
      if (component) {
        if (component && environmentId) {
          dispatch(
            addEnvComponentAction({
              environmentId,
              componentType,
              component: component as Component,
            }),
          );
          showDefaultToast(
            `${componentNames[componentType]} created.`,
            'View',
            () => {
              router.push(componentTypeConfig[componentType].redirectPath);
            },
          );
        } else {
          showErrorToast(
            `${componentNames[componentType]} not created. Please retry.`,
          );
        }
      } else {
        showErrorToast(
          `${componentNames[componentType]} not created. Please retry.`,
        );
      }
    },
    [componentTypeConfig, dispatch, router, showDefaultToast, showErrorToast],
  );

  const createComponentByType = useCallback(
    async <T extends keyof MutationTypeMap>(
      componentType: T,
      environmentId: string,
      input: MutationTypeMap[T]['input'],
    ) => {
      if (componentType === ComponentType.DATA) {
        const result = await createDataComponentFromChatResponse({
          variables: {
            input: input as MutationTypeMap[ComponentType.DATA]['input'],
          },
        });
        const component = result?.data?.createDataComponentFromChatResponse
          ?.component as Component | undefined;
        if (component && environmentId) {
          handleResultComponent(component, environmentId, componentType);
        }
      }

      if (componentType === ComponentType.STACK) {
        const result = await createStackFromDataComponent({
          variables: {
            input: input as MutationTypeMap[ComponentType.STACK]['input'],
          },
        });
        const component = result?.data?.createStackFromDataComponent
          ?.component as Component | undefined;
        if (component && environmentId) {
          handleResultComponent(component, environmentId, componentType);
        }
      }

      if (componentType === ComponentType.TOPIC) {
        const result = await createTopicFromChatResponse({
          variables: {
            input: input as MutationTypeMap[ComponentType.TOPIC]['input'],
          },
        });
        const component = result?.data?.createTopicFromDataComponent
          ?.component as Component | undefined;
        if (component && environmentId) {
          handleResultComponent(component, environmentId, componentType);
        }
      }
    },
    [
      createDataComponentFromChatResponse,
      createStackFromDataComponent,
      createTopicFromChatResponse,
      handleResultComponent,
    ],
  );

  return {
    loadEnvironmentComponentsByType,
    loading,
    editEnvironmentComponentByType,
    editComponentLoading,
    deleteEnvironmentComponentByType,
    deleteComponentLoading,
    createComponentByType,
    createComponentLoading:
      createQueryLoading || createTopicLoading || createStackLoading,
  };
};

export default useEnvironmentComponents;
