import Image from 'next/image';
import { styled } from '@mui/system';
import { Stack, Box, Typography } from '@mui/material';
import { SchemaNode, isFieldInfo } from '../../../store/slices/exploreExtracts';
import { useSessionInfo } from '../../../hooks/session';
import Button from '../../shared/Button';
import DataFieldTypeIcon from '../../DataFieldSelector/DataFieldTypeIcon';
import { DynamicFieldV1MetaData } from '@madeinventive/core-types';
import Chip from '../../shared/Chip';
import useDynamicField from '../../DynamicFieldInputs/useDynamicField';
import { useContext } from 'react';
import { EnvironmentContext } from '../../EnvironmentProvider';

export interface EmailPreviewProps {
  vizTitle: string;
  alertAbout?: SchemaNode;
  recipients: string[];
  emailBody: string;
}

const EmailPreview = ({
  vizTitle,
  alertAbout,
  recipients,
  emailBody,
}: EmailPreviewProps) => {
  const { customBranding } = useSessionInfo();
  const { environment } = useContext(EnvironmentContext);
  const {
    getValidDynamicFieldTagObjectFromText,
    convertStringToPreviewString,
  } = useDynamicField();
  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant='h6' color='text.primary'>
          Recipients
        </Typography>
        <Stack direction='row' flexWrap='wrap'>
          {recipients.map((recipient) => {
            const fieldTagObject =
              getValidDynamicFieldTagObjectFromText(recipient);
            const fieldMetaData = fieldTagObject
              ? (fieldTagObject?.metaData as DynamicFieldV1MetaData)
              : undefined;
            const label = fieldMetaData ? fieldMetaData.field : recipient;
            return (
              <Chip
                disabled
                size='small'
                key={recipient}
                label={label}
                sx={{
                  marginRight: 1.5,
                  marginBottom: 1.5,
                }}
              />
            );
          })}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant='h6' color='text.primary'>
          Message
        </Typography>
        <Box p={4} bgcolor='background.default'>
          <Box
            borderRadius={4}
            border={(theme) => `1px solid ${theme.palette.border.light}`}
            bgcolor='background.paper'
          >
            {alertAbout && isFieldInfo(alertAbout.metaData) && (
              <Stack
                width='100%'
                direction='row'
                spacing={1}
                px={4}
                py={1.5}
                alignItems='center'
                borderBottom={(theme) =>
                  `1px solid ${theme.palette.border.light}`
                }
              >
                <Typography variant='h6' color='text.primary'>
                  Your report on
                </Typography>
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing={1}
                  px={0.5}
                  py={0.25}
                  bgcolor={(theme) => theme.palette.info.wash}
                  lineHeight={1}
                  borderRadius={2}
                >
                  <DataFieldTypeIcon
                    normalizedType={alertAbout.metaData.normalizedType}
                  />
                  <Typography variant='body2' color='text.secondary'>
                    {alertAbout.metaData.name}
                  </Typography>
                </Stack>
              </Stack>
            )}
            <Stack width='100%' padding={4} spacing={3} alignItems='flex-start'>
              <Box height={24} width='100%' position='relative'>
                <Image
                  src={customBranding?.logoUrl ?? ''}
                  alt='logo'
                  fill={true}
                  objectFit='contain'
                  objectPosition='left top'
                />
              </Box>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Image
                  src={customBranding?.iconUrl ?? ''}
                  alt='icon'
                  width={32}
                  height={32}
                />
                <Stack spacing={0.5}>
                  <Typography variant='body2' color='text.secondary'>
                    <strong>{environment?.name} AI</strong> sent an alert
                  </Typography>
                  <Typography variant='caption' color='text.tertiary'>
                    5:45 AM PST
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                width='100%'
                p={2}
                spacing={1}
                borderRadius={2}
                border={(theme) => `1px solid ${theme.palette.border.light}`}
              >
                <Typography variant='h5' color='text.primary'>
                  {vizTitle}
                </Typography>
                <StyledDiv
                  dangerouslySetInnerHTML={{
                    __html: convertStringToPreviewString(emailBody),
                  }}
                />
              </Stack>
              <Button variant='outlined' disabled={false}>
                Review
              </Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default EmailPreview;

const StyledDiv = styled('div')({
  p: {
    marginTop: 0,
    marginBottom: '8px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
});
