import { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '../../shared/Button';
import Image from 'next/image';
import slackEmpty from '../../../public/slack_empty.svg';
import { API_URI } from '../../../lib/endpoints';

const SlackIntegration = ({ workspaceId }: { workspaceId: string }) => {
  const handleNewIntegration = useCallback(() => {
    const apiOrigin = new URL(API_URI).origin;
    const refererUrl = window.location.href;
    const url = new URL(
      `/integration/slack/install?workspaceId=${workspaceId}&refererUrl=${refererUrl}`,
      apiOrigin,
    ).href;
    window.location.href = url;
  }, [workspaceId]);

  return (
    <Stack spacing={4} alignItems='center'>
      <Image src={slackEmpty} alt='Empty slack integration' />
      <Stack spacing={2} alignItems='center'>
        <Typography variant='h3' color='text.primary'>
          Connect to Slack
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          Allow Inventive access to your Slack workspace to enable Slack
          notifications.
        </Typography>
        <Button variant='contained' onClick={handleNewIntegration}>
          Connect to Slack
        </Button>
      </Stack>
    </Stack>
  );
};

export default SlackIntegration;
