import { Maybe, User } from '../../generated/types';
import moment from 'moment';

interface BylineOptions {
  createdBy?: Maybe<User>;
  createdAt: string;
  updatedAt: string;
}

// always shows the Creator, and depending on whether the component has been updated,
// will show the updated timestamp instead of the created timestamp (even if the Updator is
// different from the Creator)
export const formatByline = ({
  createdBy,
  createdAt,
  updatedAt,
}: BylineOptions): string => {
  const parts: string[] = [];

  const fullName =
    `${createdBy?.firstname || ''} ${createdBy?.lastname || ''}`.trim() ||
    'Unknown';
  parts.push(`Created by ${fullName}`);

  const isUpdated = updatedAt !== createdAt;
  const prefix = isUpdated ? ' · Updated' : ' ·';
  parts.push(`${prefix} ${moment(updatedAt).format('MM/DD/YYYY hh:mm A')}`);

  return parts.join('');
};
