import { Stack } from '@mui/material';
import { FeedbackRating } from '../generated/types';
import TextField from './shared/Inputs/TextField';
import ToggleChips from './shared/ToggleChips';

const positiveTags = ['Fast', 'Relevant data', 'Good analysis', 'Delightful'];
const negativeTags = ['Slow', 'Irrelevant data', 'Bad analysis', 'Frustrating'];

interface RatingReasonsProps {
  rating: FeedbackRating;
  selectedTags: string[];
  summary: string;
  comment: string;
  onTagsChange: (tags: string[]) => void;
  onSummaryChange: (summary: string) => void;
  onCommentChange: (comment: string) => void;
  customTags?: {
    positive?: string[];
    negative?: string[];
  };
}

const RatingReasons = ({
  rating,
  selectedTags,
  summary,
  comment,
  onTagsChange,
  onSummaryChange,
  onCommentChange,
  customTags,
}: RatingReasonsProps) => {
  const handleTagClick = (tag: string) => {
    const newTags = selectedTags.includes(tag)
      ? selectedTags.filter((t) => t !== tag)
      : [...selectedTags, tag];
    onTagsChange(newTags);
  };

  const availableTags =
    rating === FeedbackRating.THUMBS_UP
      ? customTags?.positive ?? positiveTags
      : customTags?.negative ?? negativeTags;

  return (
    <Stack spacing={4} paddingY={1}>
      <ToggleChips
        options={availableTags.map((tag) => ({
          label: tag,
          value: tag,
        }))}
        selectedValues={selectedTags}
        handleItemClick={handleTagClick}
        flexWrap
      />
      <TextField
        label='Summary (optional)'
        value={summary}
        onChange={(e) => onSummaryChange(e.target.value)}
      />
      <TextField
        label='Description (optional)'
        value={comment}
        multiline
        onChange={(e) => onCommentChange(e.target.value)}
        inputProps={{ rows: 4 }}
      />
    </Stack>
  );
};

export default RatingReasons;
