import { useCallback, useEffect } from 'react';
import { useEnvironmentComponents } from '../../hooks';
import { ComponentType } from '../../generated/types';

const EnvironmentComponentsPreFetcher = ({
  environmentId,
}: {
  environmentId: string;
}) => {
  const { loadEnvironmentComponentsByType } = useEnvironmentComponents();

  const loadAllEnvironmentComponents = useCallback(async () => {
    await loadEnvironmentComponentsByType(environmentId, ComponentType.DATA);
    await loadEnvironmentComponentsByType(environmentId, ComponentType.STACK);
    await loadEnvironmentComponentsByType(environmentId, ComponentType.TOPIC);
  }, [environmentId, loadEnvironmentComponentsByType]);

  useEffect(() => {
    if (environmentId) {
      loadAllEnvironmentComponents();
    }
  }, [
    loadEnvironmentComponentsByType,
    environmentId,
    loadAllEnvironmentComponents,
  ]);

  return null;
};

export default EnvironmentComponentsPreFetcher;
