import { useFormikContext } from 'formik';
import { Stack, StackProps, Typography } from '@mui/material';
import { styled } from '@mui/system';
import TextField from '../../shared/Inputs/TextField';
// types
import { ActionsFormValues } from './types';
import { GLOBAL_CONTENT_WIDTH } from '../../Layout/constants';

// components
import Switch from '../../shared/Switch';

const SectionCard = styled((props: StackProps) => (
  <Stack {...props} spacing={4} padding={2} borderRadius={1.5} />
))(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.border.light}`,
}));

const ActionsForm = () => {
  const { setFieldValue, values, errors } =
    useFormikContext<ActionsFormValues>();

  return (
    <Stack
      id='actions-step'
      width='100%'
      maxWidth={GLOBAL_CONTENT_WIDTH}
      height='100%'
      spacing={2}
    >
      <SectionCard>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Stack>
            <Typography variant='h6' color='text.primary'>
              Include opt-in for notifications
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Users who activate notifications will receive alerts when new
              insights become available for this Stack.
            </Typography>
          </Stack>
          <Switch
            checked={values.optInNotifications}
            onChange={() =>
              setFieldValue('optInNotifications', !values.optInNotifications)
            }
          />
        </Stack>
      </SectionCard>
      <SectionCard>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h6' color='text.primary'>
            Include action button
          </Typography>
          <Switch
            checked={values.includeActionButton}
            onChange={(checked) =>
              setFieldValue('includeActionButton', checked)
            }
          />
        </Stack>
        {values.includeActionButton && (
          <TextField
            label='Button text'
            subLabel='Should be clear, concise, and action-oriented.'
            value={values.actionButtonText}
            onChange={(e) => setFieldValue('actionButtonText', e.target.value)}
            errorMessage={errors.actionButtonText}
          />
        )}
        {values.includeActionButton && (
          <TextField
            label='Button link'
            subLabel='Provide the URL for users to act on Analyst findings.'
            value={values.actionButtonLink}
            onChange={(e) => setFieldValue('actionButtonLink', e.target.value)}
            errorMessage={errors.actionButtonLink}
          />
        )}
      </SectionCard>
    </Stack>
  );
};

export default ActionsForm;
