import React from 'react';
import { Grid, Typography } from '@mui/material';
import LoadingDot from '../../LoadingDots';
import { useTheme, Theme } from '@mui/material/styles';

interface LoadingScreen {
  message?: string;
  bgColor?: (theme: Theme) => string;
  customHeight?: string;
}

const LoadingScreen = ({ message, bgColor, customHeight }: LoadingScreen) => {
  const theme = useTheme();

  return (
    <Grid
      container
      p={2}
      direction='column'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height={customHeight ?? '100vh'}
      bgcolor={bgColor}
    >
      <Grid item xs={2}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          style={{
            margin: 'auto',
            display: 'block',
            shapeRendering: 'auto',
          }}
          width='200px'
          height='200px'
          viewBox='0 0 100 100'
          preserveAspectRatio='xMidYMid'
        >
          <g transform='rotate(180 50 50)'>
            <rect
              x='15'
              y='15'
              width='10'
              height='40'
              fill={theme.palette.primary.dark}
            >
              <animate
                attributeName='height'
                values='50;70;30;50'
                keyTimes='0;0.33;0.66;1'
                dur='1s'
                repeatCount='indefinite'
                calcMode='spline'
                keySplines='0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1'
                begin='-0.4s'
              />
            </rect>
            <rect
              x='35'
              y='15'
              width='10'
              height='40'
              fill={theme.palette.primary.main}
            >
              <animate
                attributeName='height'
                values='50;70;30;50'
                keyTimes='0;0.33;0.66;1'
                dur='1s'
                repeatCount='indefinite'
                calcMode='spline'
                keySplines='0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1'
                begin='-0.2s'
              />
            </rect>
            <rect
              x='55'
              y='15'
              width='10'
              height='40'
              fill={theme.palette.primary.light}
            >
              <animate
                attributeName='height'
                values='50;70;30;50'
                keyTimes='0;0.33;0.66;1'
                dur='1s'
                repeatCount='indefinite'
                calcMode='spline'
                keySplines='0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1'
                begin='-0.6s'
              />
            </rect>
            <rect
              x='75'
              y='15'
              width='10'
              height='40'
              fill={theme.palette.primary.wash}
            >
              <animate
                attributeName='height'
                values='50;70;30;50'
                keyTimes='0;0.33;0.66;1'
                dur='1s'
                repeatCount='indefinite'
                calcMode='spline'
                keySplines='0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1'
                begin='-1s'
              />
            </rect>
          </g>
        </svg>
      </Grid>
      {message && (
        <Grid item xs={1}>
          <Typography variant='h4' mb={3} color='text.primary'>
            {message} <LoadingDot />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default LoadingScreen;
