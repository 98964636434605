import { ApolloError } from '@apollo/client';
import { createContext, ReactChild, useEffect } from 'react';
import { useEnvironment, useAppSelector, useSessionInfo } from '../../hooks';
import EnvironmentComponentsPreFetcher from '../EnvironmentComponentsPreFetcher';
import {
  CreateWorkspaceInput,
  Environment,
  Workspace,
  EnvironmentLookerDataModelExplore,
  CreateEnvironmentLookerDataModelExploreInput,
  UpdateEnvironmentLookerDataModelExploreInput,
  DeleteEnvironmentLookerDataModelExploreInput,
  DeleteWorkspaceInput,
} from '../../generated/types';
interface EnvironmentContextStore {
  fetchEnvironment: (environmentId?: string) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
  fetchEnvExplores: () => Promise<void>;
  createEnvExplore: (
    input: CreateEnvironmentLookerDataModelExploreInput,
  ) => Promise<void>;
  updateEnvExplore: (
    input: UpdateEnvironmentLookerDataModelExploreInput,
  ) => Promise<void>;
  deleteEnvExplore: (
    input: DeleteEnvironmentLookerDataModelExploreInput,
  ) => Promise<void>;
  envExploreLoading: boolean;
  envExploreError?: ApolloError;
  createWorkspaceAddExplore: (
    input: CreateWorkspaceInput,
    explore?: string,
    customerIdentifiers?: string[],
  ) => Promise<Workspace | null>;
  deleteWorkspaceFromEnvironment: (
    input: DeleteWorkspaceInput,
  ) => Promise<void>;
  // slice values
  environment?: Environment;
  workspaces?: Workspace[];
  envExplores?: EnvironmentLookerDataModelExplore[];
}

const initialState: EnvironmentContextStore = {
  fetchEnvironment: async () => {
    /** */
  },
  loading: false,
  error: undefined,
  fetchEnvExplores: async () => {
    /** */
  },
  createEnvExplore: async () => {
    /** */
  },
  updateEnvExplore: async () => {
    /** */
  },
  deleteEnvExplore: async () => {
    /** */
  },
  envExploreLoading: false,
  envExploreError: undefined,
  createWorkspaceAddExplore: async () => {
    /** */
    return null;
  },
  deleteWorkspaceFromEnvironment: async () => {
    /** */
  },
  environment: undefined,
  workspaces: undefined,
  envExplores: undefined,
};

export const EnvironmentContext = createContext(initialState);
interface EnvironmentWrapperProps {
  children: ReactChild;
}
const EnvironmentProvider = ({ children }: EnvironmentWrapperProps) => {
  const { isSignedIn, email } = useSessionInfo();
  const { environment, envExplores } = useAppSelector(
    (store) => store.environment.value,
  );
  const {
    fetchEnvironment,
    loading,
    error,
    fetchEnvExplores,
    createEnvExplore,
    updateEnvExplore,
    deleteEnvExplore,
    envExploreLoading,
    envExploreError,
    createWorkspaceAddExplore,
    deleteWorkspaceFromEnvironment,
  } = useEnvironment();

  // in case of switching to another user in the same browser
  // isSignedIn is true but email is different
  // so we need to refetch the environment to get the full workspace object
  useEffect(() => {
    if (isSignedIn) {
      fetchEnvironment();
    }
  }, [isSignedIn, email, fetchEnvironment]);

  return (
    <EnvironmentContext.Provider
      value={{
        fetchEnvironment,
        loading,
        error,
        fetchEnvExplores,
        createEnvExplore,
        updateEnvExplore,
        deleteEnvExplore,
        envExploreLoading,
        envExploreError,
        createWorkspaceAddExplore,
        deleteWorkspaceFromEnvironment,
        // slice values
        environment,
        envExplores,
      }}
    >
      {children}
      {environment?.id && (
        <EnvironmentComponentsPreFetcher environmentId={environment?.id} />
      )}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
