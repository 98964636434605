import { useCallback, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, Stack, Typography } from '@mui/material';
// components
import Icon from '../../shared/Icon';
import IconButton from '../../shared/IconButton';
import Button from '../../shared/Button';

import { IconName } from '../../shared/Icon/types';
import { GLOBAL_CONTENT_WIDTH } from '../constants';
import { Routes } from '@madeinventive/core-types';
// hooks
import { useAppSelector } from '../../../hooks';

type NavButtonConfig = {
  id: string;
  icon: IconName;
  route: string;
};

const SUBSCRIPTION_NOTIFICATION_DELAY = 5000;

const WorkspaceNavBar = ({ workspaceId }: { workspaceId: string }) => {
  const [showDelayMessage, setShowDelayMessage] = useState(false);
  const stackUIConfigs = useAppSelector(
    (state) => state.stackUIConfigs.value[workspaceId]?.stackUIConfigs,
  );
  const isSubscribing = stackUIConfigs?.some((config) => config.isLoading);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isSubscribing) {
      timeoutId = setTimeout(() => {
        setShowDelayMessage(true);
      }, SUBSCRIPTION_NOTIFICATION_DELAY);
    } else {
      setShowDelayMessage(false);
    }
    return () => clearTimeout(timeoutId);
  }, [isSubscribing]);

  const router = useRouter();
  const WorkspaceNavConfigs: NavButtonConfig[] = [
    {
      id: 'home',
      icon: 'stack',
      route: `/workspace/${workspaceId}`,
    },
    {
      id: 'chatHistory',
      icon: 'chat-double',
      route: `/workspace/${workspaceId}/chatHistory`,
    },
    {
      id: 'savedComponents',
      icon: 'bookmark-multi',
      route: `/workspace/${workspaceId}/components`,
    },
    {
      id: 'alerts',
      icon: 'bell-bolt',
      route: `/workspace/${workspaceId}/features`,
    },
  ];

  const showChatButton = useMemo(() => {
    return (
      !router.pathname.endsWith('/chat') &&
      !router.pathname.endsWith('/[componentId]')
    );
  }, [router.pathname]);

  return (
    <Stack
      px={2}
      py={1.5}
      direction='row'
      justifyContent='center'
      width='100%'
      sx={{
        position: 'relative',
        borderTop: (theme) => `1px solid ${theme.palette.border.light}`,
        boxShadow: (theme) => theme.customShadows[1],
      }}
    >
      <Stack
        id='workspace-navigation'
        overflow='hidden'
        direction='row'
        spacing={1.5}
        maxWidth={GLOBAL_CONTENT_WIDTH}
        width='100%'
      >
        {WorkspaceNavConfigs.map((config) => (
          <WorkspaceNavButton key={config.id} {...config} />
        ))}
      </Stack>
      {showChatButton && (
        <Box
          sx={{
            position: 'absolute',
            top: -16,
            left: 16,
            transform: 'translate(0%, -100%)',
          }}
          display='flex'
          flexDirection='row'
          alignItems='center'
          gap={1}
        >
          <IconButton
            size='large'
            iconName='chat-new'
            variant='contained'
            onClick={() => {
              router.push(Routes.chat(workspaceId));
            }}
            sx={{
              border: (theme) => `1px solid ${theme.palette.border.active}`,
            }}
          />
          {showDelayMessage && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.neutrals.black,
                borderRadius: 2,
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              <Typography
                variant='h6'
                sx={{ color: (theme) => theme.palette.neutrals.white }}
              >
                Have a question? Start a chat while you wait
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default WorkspaceNavBar;

const WorkspaceNavButton = ({ id, icon, route }: NavButtonConfig) => {
  const router = useRouter();
  const isSelected = useMemo(() => {
    if (id === 'home') {
      return router.asPath === route;
    } else {
      return router.asPath.includes(route);
    }
  }, [id, router, route]);

  const handleClick = useCallback(() => {
    router.push(route);
  }, [route, router]);

  return (
    <Button
      variant='text'
      fullWidth
      disableRipple
      size='small'
      aria-label={`${id}-${isSelected}`}
      className={isSelected ? 'active' : ''}
      sx={{
        borderRadius: 60,
        '&.active': {
          backgroundColor: 'primary.wash',
          border: (theme) => `1px solid ${theme.palette.border.active}`,
        },
      }}
      onClick={handleClick}
    >
      <Icon name={icon} size='small' />
    </Button>
  );
};
