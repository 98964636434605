import { useCallback, useMemo } from 'react';
import { AgentPersona, ResponseBias } from '@madeinventive/core-types';
import { INVENTIVE_ICON } from '../utils/filePaths';
import { useToast } from './useToast';
import { useEditEnvironmentMutation } from '../generated/types';
import {
  setAgentPersonaConfig,
  AgentPersonaConfig,
} from '../store/slices/environment';
import { useAppDispatch, useAppSelector } from './store';

const DEFAULT_AGENT_WELCOME_TITLE = 'Hello, how can I help?';
const DEFAULT_AGENT_WELCOME_SUBTITLE =
  'Fuel your next big idea. Explore your data, ask big questions, and create.';
const DEFAULT_DISCLAIMER =
  'AI can get confused. Evaluate responses critically.';

// core-types type to redux store type
const convertAgentPersonaToAgentPersonaConfig = (
  agentPersona: AgentPersona,
  defaultAgentProfile: AgentPersonaConfig,
): AgentPersonaConfig => {
  return {
    analystName: agentPersona?.name ?? defaultAgentProfile.analystName,
    profileIconUrl:
      agentPersona?.profileIconUrl ?? defaultAgentProfile.profileIconUrl,
    welcomeMsgTitle:
      agentPersona?.welcomeMsgTitle ?? defaultAgentProfile.welcomeMsgTitle,
    welcomeMsgSubTitle:
      agentPersona?.welcomeMsgSubTitle ??
      defaultAgentProfile.welcomeMsgSubTitle,
    disclaimer: agentPersona?.disclaimer ?? defaultAgentProfile.disclaimer,
    responseBias:
      agentPersona?.responseBias ?? defaultAgentProfile.responseBias,
    analystHints:
      agentPersona?.specialInstructions ?? defaultAgentProfile.analystHints,
  };
};

// redux store type to core-types type
const convertAgentPersonaConfigToAgentPersona = (
  agentPersonaConfig: AgentPersonaConfig,
): AgentPersona => {
  return {
    name: agentPersonaConfig.analystName,
    profileIconUrl: agentPersonaConfig.profileIconUrl,
    welcomeMsgTitle: agentPersonaConfig.welcomeMsgTitle,
    welcomeMsgSubTitle: agentPersonaConfig.welcomeMsgSubTitle,
    disclaimer: agentPersonaConfig.disclaimer,
    responseBias: agentPersonaConfig.responseBias,
    specialInstructions: agentPersonaConfig.analystHints,
  };
};

export const useEnvironmentAgentProfile = () => {
  const storeDispatch = useAppDispatch();
  const { environment, agentPersonaConfig, isLoading } = useAppSelector(
    (state) => state.environment.value,
  );
  const {
    analystName,
    profileIconUrl,
    welcomeMsgTitle,
    welcomeMsgSubTitle,
    disclaimer,
  } = agentPersonaConfig;
  const { showSuccessToast, showErrorToast } = useToast();
  const [editEnvironment] = useEditEnvironmentMutation();

  const defaultAgentProfile = useMemo<AgentPersonaConfig>(
    () => ({
      analystName: `${environment?.name} AI`,
      profileIconUrl: INVENTIVE_ICON,
      welcomeMsgTitle: DEFAULT_AGENT_WELCOME_TITLE,
      welcomeMsgSubTitle: DEFAULT_AGENT_WELCOME_SUBTITLE,
      disclaimer: DEFAULT_DISCLAIMER,
      responseBias: ResponseBias.NEUTRAL,
    }),
    [environment],
  );

  const handleReceiveAgentPersona = useCallback(
    (agentPersona: AgentPersona) => {
      const typedAgentPersonaConfig = convertAgentPersonaToAgentPersonaConfig(
        agentPersona,
        defaultAgentProfile,
      );

      // add timestamp to profileIconUrl to avoid caching issues
      // it updates the URL only when the agent profile is updated
      storeDispatch(
        setAgentPersonaConfig({
          ...typedAgentPersonaConfig,
          profileIconUrl: `${
            typedAgentPersonaConfig.profileIconUrl
          }?t=${Date.now()}`,
        }),
      );
    },
    [defaultAgentProfile, storeDispatch],
  );

  const updateAgentProfile = useCallback(
    async (agentPersonaConfig: AgentPersonaConfig) => {
      if (!environment?.id) {
        return;
      }
      const typedAgentPersona =
        convertAgentPersonaConfigToAgentPersona(agentPersonaConfig);

      try {
        const result = await editEnvironment({
          variables: {
            input: {
              id: environment.id,
              fields: {
                name: environment.name, // name is required
                agentPersonaConfig: typedAgentPersona,
              },
            },
          },
        });
        const agentPersona =
          result.data?.editEnvironment?.environment.agentPersonaConfig;
        if (agentPersona) {
          handleReceiveAgentPersona(agentPersona);
          showSuccessToast('Agent profile updated');
        }
      } catch {
        showErrorToast('Failed to update agent profile');
      }
    },
    [
      editEnvironment,
      environment,
      handleReceiveAgentPersona,
      showErrorToast,
      showSuccessToast,
    ],
  );

  const resetAgentProfile = useCallback(async () => {
    if (!environment?.id) {
      return;
    }

    const typedAgentPersona =
      convertAgentPersonaConfigToAgentPersona(defaultAgentProfile);

    try {
      const result = await editEnvironment({
        variables: {
          input: {
            id: environment.id,
            fields: {
              name: environment.name, // name is required
              agentPersonaConfig: typedAgentPersona,
            },
          },
        },
      });
      if (result) {
        storeDispatch(setAgentPersonaConfig(defaultAgentProfile));
        showSuccessToast('Agent profile updated');
      }
    } catch {
      showErrorToast('Failed to reset agent profile');
    }
  }, [
    defaultAgentProfile,
    editEnvironment,
    environment,
    showErrorToast,
    showSuccessToast,
    storeDispatch,
  ]);

  return {
    analystName,
    profileIconUrl,
    welcomeMsgTitle,
    welcomeMsgSubTitle,
    disclaimer,
    defaultAgentProfile,
    updateAgentProfile,
    handleReceiveAgentPersona,
    resetAgentProfile,
    isLoading,
  };
};
