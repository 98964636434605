import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setUIState, getUIState } from '../../lib/localStorage';
import { Component } from '../../generated/types';

const NEW_COMPONENT_IDS_KEY = 'newComponentIds';

interface WorkspaceComponentsState {
  components: Component[] | null;
  newComponentIds: string[];
}

const initialState: WorkspaceComponentsState = {
  components: null,
  newComponentIds: [],
};

// Slice for saved viz components (workspace level)
const workspaceComponentsSlice = createSlice({
  name: 'workspaceComponents',
  initialState: { value: initialState },
  reducers: {
    setComponents: (state, action: PayloadAction<Component[]>) => {
      state.value = {
        ...state.value,
        components: action.payload,
      };
    },
    updateComponentName: (
      state,
      action: PayloadAction<{ id: string; name: string }>,
    ) => {
      const { id, name } = action.payload;
      const component = state.value.components?.find((c) => c.id === id);
      if (component) {
        component.name = name;
      }
    },
    loadInitialNewComponentIds: (state) => {
      const componentIds = getUIState(NEW_COMPONENT_IDS_KEY);
      if (componentIds) {
        state.value.newComponentIds = JSON.parse(componentIds);
      }
    },
    addNewComponentId: (state, action: PayloadAction<string>) => {
      state.value.newComponentIds.push(action.payload);
      setUIState(
        NEW_COMPONENT_IDS_KEY,
        JSON.stringify(state.value.newComponentIds),
      );
    },
    removeNewComponentId: (state, action: PayloadAction<string>) => {
      state.value.newComponentIds = state.value.newComponentIds.filter(
        (id) => id !== action.payload,
      );
      setUIState(
        NEW_COMPONENT_IDS_KEY,
        JSON.stringify(state.value.newComponentIds),
      );
    },
  },
});

export const {
  setComponents,
  updateComponentName,
  loadInitialNewComponentIds,
  addNewComponentId,
  removeNewComponentId,
} = workspaceComponentsSlice.actions;

export default workspaceComponentsSlice.reducer;
